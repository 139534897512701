// Generated by Framer (716dd6f)

import { addFonts, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {QvrUlyaNG: {hover: true}};

const cycleOrder = ["QvrUlyaNG"];

const variantClassNames = {QvrUlyaNG: "framer-v-13yglzz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "QvrUlyaNG", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "QvrUlyaNG", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mcUAy", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Image {...restProps} background={{alt: "Sona designer - building Sivi", fit: "fill", intrinsicHeight: 1349, intrinsicWidth: 1334, pixelHeight: 1349, pixelWidth: 1334, src: new URL("assets/ARL4jH5tusSLHszxhDdZvX3p1GY.png", import.meta.url).href, srcSet: `${new URL("assets/512/ARL4jH5tusSLHszxhDdZvX3p1GY.png", import.meta.url).href} 512w, ${new URL("assets/1024/ARL4jH5tusSLHszxhDdZvX3p1GY.png", import.meta.url).href} 1024w, ${new URL("assets/ARL4jH5tusSLHszxhDdZvX3p1GY.png", import.meta.url).href} 1349w`}} className={cx("framer-13yglzz", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QvrUlyaNG"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"QvrUlyaNG-hover": {"data-framer-name": undefined, background: {alt: "Sona designer - building Sivi", fit: "fill", intrinsicHeight: 1349, intrinsicWidth: 1349, pixelHeight: 1349, pixelWidth: 1349, src: new URL("assets/ZLYIh9KXTjXGKnNaKmKPNZbzcU.png", import.meta.url).href, srcSet: `${new URL("assets/512/ZLYIh9KXTjXGKnNaKmKPNZbzcU.png", import.meta.url).href} 512w, ${new URL("assets/1024/ZLYIh9KXTjXGKnNaKmKPNZbzcU.png", import.meta.url).href} 1024w, ${new URL("assets/ZLYIh9KXTjXGKnNaKmKPNZbzcU.png", import.meta.url).href} 1349w`}}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mcUAy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mcUAy * { box-sizing: border-box; }", ".framer-mcUAy .framer-1shgesa { display: block; }", ".framer-mcUAy .framer-13yglzz { height: 638px; overflow: visible; position: relative; width: 618px; }", ".framer-mcUAy .framer-v-13yglzz .framer-13yglzz { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 638
 * @framerIntrinsicWidth 618
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"MMWgI1wXP":{"layout":["fixed","fixed"]}}}
 */
const FramerXLM6JC4Ve: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerXLM6JC4Ve;

FramerXLM6JC4Ve.displayName = "sona-cofounder-sivi";

FramerXLM6JC4Ve.defaultProps = {height: 638, width: 618};

addFonts(FramerXLM6JC4Ve, [])